.my-button {
    display: inline-block;
    outline: 0;
    cursor: pointer;
    border-radius: 6px;
    border: 2px solid #000000;
    color: #000000;
    background-color: #FFFFFF;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
    font-weight: 800;
    font-size: 16px;
    height: 42px;
   
}

.my-button:hover{
    background-color: #000000;
    color: #FFFFFF;
}
